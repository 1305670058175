import React from "react"
import {graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInner";
import { Grid } from '@material-ui/core'
import * as PATHS from "../../utils/paths";
import {joinPaths} from "../../utils";

const styles = theme => ({

    image: {
        marginBottom: 0,
        maxWidth: '100%',
        border: "1px solid " + theme.palette.text.secondary,
    },
  heroText: {
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: 400,
    marginBottom: 16
  },

})

const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title

  const featuredSlugs = new Set([
    "/survey-research-data-exploration-product-design/",
    "/lawatlas-temple-academic-open-dataset-visualization-design/",
    "/consumer-health-tracking-data-visualization-design/",
    "/timely-advice-how-long-does-dataviz-take-auC2KawtRB2Gvy2IMHPwLA/",
    "/winning-at-ratemyskyperoom-elaborate-and-pointless-analysis-3BZT_LMuTrynOe2_EjD5Gw/"
  ])
    return (

      <Layout location={location} title={siteTitle}>
          <SEO title={"Hello Nightingale"} location={{pathname: PATHS.CROWD_PAGE}}/>

          <LayoutInner>
              <Grid container className={classes.root}

                    spacing={4} direction={'row'}>

                  <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                      <img className={classes.image}
                           src={joinPaths([PATHS.CDN, "/images/3iap-print-ad-for-nightingale-web.png"])}/>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingBottom: 0 }}>
                      <h2>Hello Nightingale!</h2>
                    <p>3iap proudly supports Nightingale and the Data Visualization Society.</p>
                    <p>We're also proud contributors. Below are a few crowd-pleasing articles we've written for Nightingale:</p>
                    <ul style={{paddingInlineStart: 16}}>
                      <li style={{marginBottom: 8}}><a href={"https://nightingaledvs.com/unfair-comparisons-how-visualizing-social-inequality-can-make-it-worse/"}>Unfair Comparisons: How Visualizing Social Inequality Can Make It Worse</a></li>
                      <li style={{marginBottom: 8}}><a href={"https://nightingaledvs.com/timely-advice-how-long-does-dataviz-take/"}>Timely Advice: How Long Does Dataviz Take?</a></li>
                      <li style={{marginBottom: 8}}><a href={"https://nightingaledvs.com/dashboard-psychology-effective-feedback-in-data-design/"}>Dashboard Psychology: Effective Feedback in Data Design</a></li>
                    </ul>
                  </Grid>


              </Grid>
          </LayoutInner>
      </Layout>


    )
}


export const Page = withStyles(styles)(_Page)
export default Page


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`


